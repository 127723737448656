<template>
	<div class="page match_page">
		<div class="block30"></div>
		<div class="w1240 match_box">
			<div class="datalist">
				<div class="itemobj fontsize16" v-for="(item,index) in dataList" :key="index">
					<div class="text1">
						<img class="icon" src="../assets/static/Frame2201.png"/>
						<div class="text clamp">{{item.title}}</div>
					</div>
					<div class="block10"></div>
					<div class="flexrow text2">
						<div class="typename">开始时间</div>
						<div class="text">{{item.examStartTime}}</div>
					</div>
					<div class="flexrow text3">
						<div class="typename">结束时间</div>
						<div class="text">{{item.examEndTime}}</div>
					</div>
					<div class="flexrow text4">
						<div class="typename">参与人数</div>
						<div class="text">{{item.userUuidList?item.userUuidList.length:0}}</div>
					</div>
					<div class="flexrow text5">
						<div class="typename">状 态</div>
						<div class="text">
							<span class="c1" v-if="item.typeTag=='ing'">进行中</span>
							<span class="c2" v-if="item.typeTag=='end'">已结束</span>
							<span class="c3" v-if="item.typeTag=='before'">未开始</span>
						</div>
					</div>
					<div class="flexrow text6">
						<div class="typename">难 度</div>
						<div class="text">{{difficultyList[item.difficulty]}}</div>
					</div>
					<div class="text7" @click="gotoworkdetail(item)">进入比赛</div>
					<div class="block20"></div>
				</div>
			</div>
			<div class="datalist" v-if="dataList.length<=0">
				<div class="nolist">赛事/考试暂无开启，敬请期待。</div>
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dataList:[],
		};
	},
	mounted: function() {
		if(this.isLogin){
			//获取我的赛事
			this.getmhlQuestExam()
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole','difficultyList']),
	},
	methods: {
		//跳转详情
		gotoworkdetail(item){
			// if(item.typeTag=="before"){
			// 	this.$message.info("答题时间未开始！")
			// 	return false;
			// }
			// if(item.typeTag=="end"){
			// 	this.$message.info("答题时间已结束！")
			// 	return false;
			// }
			// ing
			// this.$router.push({
			// 	path: "/myworkdetail",
			// 	query:{
			// 		uid:item.id,
			// 		index:0
			// 	}
			// });
			this.$router.push({
				path: "/matchwork",
				query:{
					uid:item.id
				}
			});
		},
		//获取我的赛事
		getmhlQuestExam(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {
					testType:0,//测试类型 0比赛 1作业
					currentPage:1,
					pageSize:8888,
				}
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('app/hlexam/hlQuestExam/index', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						res.data.records.forEach((item, index) => {
							// console.log(row)
							var examStartTime = _this.$util.getDateTime2(item.examStartTime)
							var examEndTime = _this.$util.getDateTime2(item.examEndTime)
							try{
								item["typeTag"] = _this.$util.compareTimes(examStartTime,examEndTime)
							}catch(e){
								item["typeTag"] = ""
							}
						});
						_this.dataList = res.data.records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
